/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, useIntl } from "react-intl";
import moment from "moment";
import { useUnmount } from "react-use";
import { isEqual } from "lodash";
import ModalFillInfo from "../../giftItem/components/ModalFillInfo";
import ModalGiftCode from "../../giftItem/components/ModalGiftCode";
import ChangeCodeDialog from "../../account/components/ChangeCodeDialog";
import CongratulationAlertDialog from "../../common/dialog/CongratulationAlertDialog";
import { TAccountState } from "../../account/utils/types";
import { TGiftItemState } from "../../giftItem/utils/types";
import { giftAction } from "../../giftItem/redux/action";
import { TYPE_GIFT_OTHER, checkNumberPhone } from "../../giftItem/utils/constant";
import { accountActions } from "../../account/redux/actions";
import { genSignature, getNonce, isEmpty } from "../../../utils/helpers/helpers";
import AlertDialog from "../dialog/AlertDialog";
import { commonActions } from "../redux/actions";

function ExchangeGiftItem({ giftInfo, handleClose, handleSuccess }) {
  const intl = useIntl();
  const dispatch = useDispatch();
  const giftCodeRef = useRef("");
  const notifyGiftCode = useSelector((state: { account: TAccountState }) => state.account.notify);
  const notify = useSelector((state: { giftItem }) => state.giftItem.notify);

  const { giftItemExchange } = useSelector((state: { giftItem: TGiftItemState }) => state.giftItem);
  const [openModalFillInfo, setOpenModalFillInfo] = useState(false);
  const [openModalGiftCode, setOpenModalGiftCode] = useState(false);
  const [modalFillGiftCode, setModalFillGiftCode] = useState(false);
  const [extraInfoExchangeGift, setExtraInfoExchangeGift] = useState<any>({
    valuePhone: "",
    errorPhone: "",
    valueNote: ""
  });

  const handleOpenModalGiftCode = () => {
    setOpenModalGiftCode(true);
  };
  const handleExchangeGiftItem = () => {
    let dataGiftItemExchange: any = {
      timestamp: moment().unix(),
      nonce: getNonce(),
      gift_id: giftInfo?.gift_item_id
    };
    if (giftInfo?.type === TYPE_GIFT_OTHER) {
      if (extraInfoExchangeGift?.valuePhone) {
        dataGiftItemExchange = {
          ...dataGiftItemExchange,
          phone: extraInfoExchangeGift?.valuePhone
        };
      }
      if (extraInfoExchangeGift?.valueNote) {
        dataGiftItemExchange = {
          ...dataGiftItemExchange,
          note: extraInfoExchangeGift?.valueNote.trim()
        };
      }
      const params: any = {
        ...dataGiftItemExchange,
        signature: genSignature(dataGiftItemExchange)
      };
      dispatch(giftAction.handleGetGiftItemExchange(params));
      if (notify?.type === "GET_GIFT_ITEM_EXCHANGE_SUCCESS") {
        handleOpenModalGiftCode();
      }
    } else {
      const params: any = {
        ...dataGiftItemExchange,
        signature: genSignature(dataGiftItemExchange)
      };

      dispatch(giftAction.handleGetGiftItemExchange(params));
      handleOpenModalGiftCode();
    }
  };

  const handleCloseModal = () => {
    setOpenModalFillInfo(false);
    setExtraInfoExchangeGift({ valuePhone: "", errorPhone: "", valueNote: "" });
    handleClose();
  };

  const closeModalGiftCode = () => {
    setOpenModalGiftCode(false);
    dispatch(giftAction?.handleClearNotify());
    handleSuccess();
    setExtraInfoExchangeGift({ valuePhone: "", errorPhone: "", valueNote: "" });
  };

  const handleChangePhone = (e) => {
    if (!e.target.value.startsWith(" ")) {
      if (checkNumberPhone(e.target.value)) {
        setExtraInfoExchangeGift({ ...extraInfoExchangeGift, valuePhone: e.target.value, errorPhone: "" });
      } else {
        setExtraInfoExchangeGift({
          ...extraInfoExchangeGift,
          valuePhone: e.target.value,
          errorPhone: intl.formatMessage({ id: "gift.modal.info.phone.error" })
        });
      }
    }
  };

  const handleChangeNote = (e) => {
    if (!e.target.value.startsWith(" ")) {
      setExtraInfoExchangeGift({ ...extraInfoExchangeGift, valueNote: e.target.value });
    }
  };

  const handleChangeGiftCode = () => {
    setModalFillGiftCode(true);
    setOpenModalGiftCode(false);
  };

  const handleCloseModalFillGiftCode = () => {
    setModalFillGiftCode(false);
    handleSuccess();
    setExtraInfoExchangeGift({ valuePhone: "", errorPhone: "", valueNote: "" });
  };
  useEffect(() => {
    if (notify?.type === "GET_GIFT_ITEM_EXCHANGE_SUCCESS") {
      if (giftInfo?.type === TYPE_GIFT_OTHER) {
        setOpenModalFillInfo(false);
      }
      handleOpenModalGiftCode();

      giftCodeRef.current = giftItemExchange?.gift_code;
      dispatch(giftAction.handleClearNotify());
      if (giftInfo?.fee?.diamond?.diamond > 0) {
        dispatch(commonActions.handleGetUserInfo());
      }
    }
  }, [notify]);
  useEffect(() => {
    if (!isEmpty(giftInfo)) {
      if (giftInfo?.type === TYPE_GIFT_OTHER) {
        setOpenModalFillInfo(true);
      } else {
        handleExchangeGiftItem();
      }
    }
  }, [giftInfo]);
  useUnmount(() => {
    dispatch(giftAction.handleClearNotify());
    setExtraInfoExchangeGift({ valuePhone: "", errorPhone: "", valueNote: "" });
  });

  return (
    <div>
      {openModalFillInfo && (
        <ModalFillInfo
          openModalFillInfo={openModalFillInfo}
          closeModal={handleCloseModal}
          valuePhone={extraInfoExchangeGift.valuePhone}
          changePhone={handleChangePhone}
          changeNote={handleChangeNote}
          valueNote={extraInfoExchangeGift.valueNote}
          submit={() => handleExchangeGiftItem()}
          errorPhone={extraInfoExchangeGift.errorPhone}
          notify={notify}
        />
      )}

      {openModalGiftCode && giftItemExchange?.gift_code && (
        <ModalGiftCode
          openModalGiftCode={openModalGiftCode}
          isCleanGiftCode={!modalFillGiftCode}
          giftCode={giftItemExchange?.gift_code}
          closeModal={closeModalGiftCode}
          typeGift={giftInfo?.type}
          handleChangeGiftCode={handleChangeGiftCode}
          setOpenModalGiftCode={setOpenModalGiftCode}
          isHeader={false}
        />
      )}
      {modalFillGiftCode && (
        <ChangeCodeDialog
          valueCode={giftCodeRef.current}
          open={modalFillGiftCode}
          setOpen={setModalFillGiftCode}
          closeModalChangeCode={handleCloseModalFillGiftCode}
        />
      )}
      {notifyGiftCode.isOpenDialog && (
        <CongratulationAlertDialog
          data={notifyGiftCode.congratulation}
          openDialog={notifyGiftCode.isOpenDialog}
          handleCloseDialog={() => {}}
          triggerFunc={() => {
            dispatch(accountActions.handleClearNotify());
            handleSuccess();
            dispatch(commonActions.handleGetUserInfo());
          }}
        />
      )}
      {notify?.isOpenDialog && (
        <AlertDialog
          title={<FormattedMessage id="Setting.notification" />}
          open={notify?.isOpenDialog}
          setOpen={() => {}}
          triggerFunc={() => {
            dispatch(giftAction.handleClearNotify());
            handleSuccess();
          }}
          content={`${notify?.message}(${notify?.errorCode})`}
          buttonOkKey="common.ok"
          notifyError
          isHideCancel
        />
      )}
    </div>
  );
}
const checkEqualProps = (prevProps, nextProps) => {
  return isEqual(prevProps?.giftInfo, nextProps?.giftInfo);
};
export default React.memo(ExchangeGiftItem, checkEqualProps);
