/* eslint-disable no-unused-vars */
import { Box } from "@mui/system";
import React from "react";
import { isEqual } from "lodash";
import { eventTracking } from "../../../firebase/firebaseConfig";
import LoadingSvg from "../../common/Image/LoadingSvg";
import { TSubjectType, TTopListResponse } from "../../common/utils/type";
import { findFirstSubjectBySubjectType } from "../utils/constants";

type TProps = {
  displayList: TTopListResponse["display_list"];
  subjectList: TSubjectType[];
  handleOpenModalChooseBook: (id: number, slug: string) => void;
};
function Display({ displayList, subjectList, handleOpenModalChooseBook }: TProps) {
  return (
    <Box
      className="flex flex-col bg-white py-[24px] px-[16px] lg:rounded-[6px] md:rounded-[6px] sm:rounded-0 md:w-full sm:w-screen text-black"
      id="home_page_display"
    >
      <span className="text-black font-semibold text-lg mb-2" id="home_page_display_title">
        {displayList?.title || ""}
      </span>
      <Box
        style={{
          gridTemplateColumns: "repeat(5, 1fr)",
          display: "grid",
          gap: "12x"
        }}
        className="sm:gap-x-5 sm:overflow-x-auto w-full md:gap-12x md:overflow-visible scrollbar-hide"
      >
        {subjectList?.map((ele, index) => {
          return (
            <Box
              position="relative"
              key={`${index}`}
              style={{ cursor: "pointer" }}
              onClick={() => {
                eventTracking(
                  "select_display_list_item",
                  { slug: ele?.slug, title: ele?.title, id: ele?.id, link: null },
                  () => handleOpenModalChooseBook(ele?.id, ele?.slug)
                );
              }}
              display="flex"
              mt={2}
              flexDirection="column"
              alignItems="center"
              id="home_page_display_item"
            >
              <LoadingSvg
                fileName={findFirstSubjectBySubjectType(displayList?.items, ele?.id)?.asset_photo}
                netPhoto={findFirstSubjectBySubjectType(displayList?.items, ele?.id)?.net_photo}
                styleSvg={undefined}
                styleImg={undefined}
                id="home_page_display_item_image"
              />
              <span
                style={{ maxWidth: 120, textAlign: "center" }}
                className="text-[15px] mt-1"
                id="home_page_display_item_title"
              >
                {ele?.title}
              </span>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}

const checkEqualProps = (prevProps, nextProps) => {
  return isEqual(prevProps?.displayList, nextProps?.displayList);
};

export default React.memo(Display, checkEqualProps);
