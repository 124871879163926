import React from "react";

export default function IconStar({ width, height, className}: any) {
  return (
    <svg
      width={width || "12"}
      height={height || "12"}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M6.86447 1.75531L7.74447 3.51531C7.86447 3.76031 8.18447 3.99531 8.45447 4.04031L10.0495 4.30531C11.0695 4.47531 11.3095 5.21531 10.5745 5.94531L9.33447 7.18531C9.12447 7.39531 9.00947 7.80031 9.07447 8.09031L9.42947 9.62531C9.70947 10.8403 9.06447 11.3103 7.98947 10.6753L6.49447 9.79031C6.22447 9.63031 5.77947 9.63031 5.50447 9.79031L4.00947 10.6753C2.93947 11.3103 2.28947 10.8353 2.56947 9.62531L2.92447 8.09031C2.98947 7.80031 2.87447 7.39531 2.66447 7.18531L1.42447 5.94531C0.694466 5.21531 0.929466 4.47531 1.94947 4.30531L3.54447 4.04031C3.80947 3.99531 4.12947 3.76031 4.24947 3.51531L5.12947 1.75531C5.60947 0.800313 6.38947 0.800313 6.86447 1.75531Z"
        fill="#FFD600"
      />
    </svg>
  );
}
