/* eslint-disable react/require-default-props */
/* eslint-disable no-unused-vars */
import { Dialog, IconButton, Link } from "@mui/material";
import React from "react";
import { useStore } from "react-redux";
import { eventTracking } from "../../../firebase/firebaseConfig";
import { XCircleBlack2 } from "../../../../public/assets/icons/XCircleBlack";
import { TDataAdsInHouse } from "../../common/utils/type";

type TProps = {
  adsInHouse?: TDataAdsInHouse;
  open: boolean;
  onClose: () => void;
  handleClickAds: any;
  isShowAdsDialogFirstTime: boolean;
};

function DialogAdsInHouse({ adsInHouse, open, onClose, handleClickAds, isShowAdsDialogFirstTime }: TProps) {
  const store = useStore();
  // const adsShape = adsInHouse?.shape?.split("_") as any[];
  if (!isShowAdsDialogFirstTime) return null;
  return (
    <Dialog open={open} onClose={onClose}>
      <IconButton className="absolute right-0 top-0" aria-label="close" onClick={onClose}>
        <XCircleBlack2 />
      </IconButton>
      <Link
        key={adsInHouse?.activity_uid}
        href={adsInHouse?.link}
        onClick={() =>
          eventTracking("select_home_ads_item", { link: adsInHouse?.link, item_id: adsInHouse?.activity_uid }, () => {
            handleClickAds(store, adsInHouse?.activity_uid);
          })
        }
        id="home_page_ads_in_house_item"
      >
        <a href={adsInHouse?.link} target="_blank" rel="noreferrer">
          <img
            src={adsInHouse?.media}
            alt={adsInHouse?.content}
            className="object-scale-down max-h-[80vh]"
          />
        </a>
      </Link>
    </Dialog>
  );
}

export default DialogAdsInHouse;
