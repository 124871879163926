/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useTheme } from "@mui/material";
import { useRouter } from "next/router";
import React from "react";
import { isEqual } from "lodash";
import { eventTracking } from "../../../firebase/firebaseConfig";
import { TTheme } from "../../../utils/configs/setupTheme";
import { isUrlValid } from "../utils/constants";
import NextImage from "../../common/Image/NextImage";

type TProps = {
  recommendItem: any;
};

const RecommendCard: React.FC<TProps> = ({ recommendItem }) => {
  const router = useRouter();
  const theme: TTheme = useTheme();  
  return (
    <div className={`rounded-md w-full h-full ${recommendItem?.isAds ? "inline" : "flex flex-col"}`}>
      <NextImage
        className={`w-full ${recommendItem?.isAds ? "rounded-md object-fill aspect-[3/1.45]" : "rounded-t-md object-cover aspect-[3/1]"} `}
        src={recommendItem.photo_url}
        alt={recommendItem?.title}
        onClick={() => {
          if (recommendItem?.isAds) {
            router?.push(recommendItem?.web_link);
          }
        }}
        id="recommended_item_image"
      />
      {!recommendItem?.isAds && (
        <div
          className="w-full flex-col px-2 py-1 rounded-b-md text-start"
          style={{
            backgroundColor: recommendItem.background_color || "#ffffff"
          }}
        >
          <div
            className="text-[15px] h-[24px]"
            style={{
              color: recommendItem?.title_color
            }}
          >
            <button
              type="button"
              onClick={() =>
                isUrlValid(recommendItem?.web_link as string) && router.push(recommendItem?.web_link as string)
              }
              className=" inline h-full text-left"
              id="recommended_item_title"
            >
              <span className="line-clamp-1"> {recommendItem?.title}</span>
            </button>
          </div>
          <div
            className={`w-full flex flex-row items-center h-[34px] ${
              recommendItem?.short_description ? "justify-between" : "justify-end"
            } `}
          >
            <button
              type="button"
              className={`text-[12px] w-full flex text-left ${recommendItem?.button_config ? "w-3/4" : "w-full"}`}
              style={{
                color: recommendItem?.short_description_color || theme.palette.primary.gray400
              }}
              onClick={() =>
                eventTracking(
                  "select_recommend_item",
                  { slug: recommendItem?.subject_slug, title: recommendItem?.title, link: recommendItem?.web_link },
                  () =>
                    isUrlValid(recommendItem?.web_link as string)
                      ? router.push(recommendItem?.web_link as string)
                      : null
                )
              }
              id="recommended_item_description"
            >
              <span className="text-start line-clamp-2 ">{recommendItem?.short_description}</span>
            </button>
            {recommendItem?.button_config && (
              <button
                type="button"
                style={{
                  color: recommendItem?.button_config?.text_color || "black",
                  backgroundColor: recommendItem?.button_config?.background_color || "white",
                  borderColor: recommendItem?.button_config?.border_color || "white"
                }}
                className="px-4 py-1.5 rounded-[30px] text-xs"
                onClick={() =>
                  eventTracking(
                    "select_recommend_item",
                    { slug: recommendItem?.subject_slug, title: recommendItem?.title, link: recommendItem?.web_link },
                    () =>
                      isUrlValid(recommendItem?.web_link as string)
                        ? router.push(recommendItem?.web_link as string)
                        : null
                  )
                }
                id="recommended_item_btn"
              >
                <span className="w-full line-clamp-1"> {recommendItem?.button_config?.text_content}</span>
              </button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
const checkEqualProps = (prevProps, nextProps) => {
  return isEqual(prevProps?.recommendItem, nextProps?.recommendItem)
}
export default React.memo(RecommendCard, checkEqualProps);
