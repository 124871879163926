import { useMediaQuery } from "@mui/material";
import React from "react";
import { useRouter } from "next/router";
import Image from "next/image";
import Link from "next/link";
import { isEqual } from "lodash";
import { TFeatureList } from "../../common/utils/type";
import Logo1 from "../../../../public/assets/icons/Logo1";
import CustomSlider from "../../common/components/CustomSlider";
import { isUrlValid } from "../utils/constants";
import { eventTracking } from "../../../firebase/firebaseConfig";

type TProps = {
  title: string;
  items: TFeatureList[];
};
function HotSubject({ items, title }: TProps) {
  const navigate = useRouter();
  const isMobile = useMediaQuery("(min-width:520px)");
  return (
    <div className="p-4 bg-white lg:rounded-[6px] md:rounded-[6px] sm:rounded-0 md:w-full sm:w-screen text-black">
      <div className="text-black font-semibold text-lg mb-2 line-clamp-2">{title}</div>
      <div className="flex items-center justify-start flex-row gap-4">
        {items?.length > 3 ? (
          <CustomSlider
            isDots={false}
            autoplay
            slidesToScroll={1}
            slidesToShow={!isMobile ? 3 : 5}
            className="w-full "
            centerMode={false}
          >
            {items?.map((ele, idx) => {
              return (
                <Link
                  // type="button"
                  className="flex flex-col items-center w-[80px] h-[130px]"
                  href={isUrlValid(ele?.web_link) ? ele?.web_link : "/"}
                  key={`${idx}`}
                  // onClick={() => {
                  //   eventTracking(
                  //     "select_featured_item",
                  //     { title: ele?.title, id: ele?.id, link: ele?.web_link },
                  //     () => {}
                  //   );
                  // }}
                  id="home_page_hot_subject_item"
                >
                  <a href={isUrlValid(ele?.web_link) ? ele?.web_link : "/"}>
                    <div className="w-[80px] h-[80px] flex justify-center items-center">
                      {ele?.photo_url?.length > 0 ? (
                        <Image src={ele?.photo_url} alt={ele?.title} width={64} height={64} className="object-cover" sizes="100vw"/>
                      ) : (
                        <Logo1 />
                      )}
                    </div>
                    <span className="mt-2 capitalize text-[15px] line-clamp-2 w-full h-[40px]">{ele.title}</span>
                  </a>
                </Link>
              );
            })}
          </CustomSlider>
        ) : (
          <div className="flex items-center justify-start flex-row w-full gap-4">
            {items?.map((ele, idx) => {
              return (
                <button
                  type="button"
                  className="flex flex-col items-center w-[80px] h-[130px]"
                  key={`${idx}`}
                  onClick={() =>
                    eventTracking(
                      "select_featured_item",
                      { title: ele?.title, id: ele?.id, link: ele?.web_link },
                      () => isUrlValid(ele?.web_link) && navigate.push(ele?.web_link)
                    )
                  }
                  id="home_page_hot_subject_item"
                >
                  <div className="capitalize w-[80px] h-[80px] flex justify-center items-center relative">
                    {ele?.photo_url?.length > 0 ? (
                      <Image src={ele?.photo_url} alt={ele?.title} layout="fill" className="object-cover" />
                    ) : (
                      <Logo1 />
                    )}
                  </div>
                  <span className="mt-2 capitalize text-[15px] line-clamp-2 h-[40px]">{ele.title}</span>
                </button>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}
const checkEqualProps = (prevProps, nextProps) => {
  return isEqual(prevProps?.items, nextProps?.items);
};
export default React.memo(HotSubject, checkEqualProps);
