/* eslint-disable react/jsx-no-undef */
/* eslint-disable react/jsx-filename-extension */
import { Typography, useTheme } from "@mui/material";
import Rating from "@mui/material/Rating";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Box } from "@mui/system";
import React from "react";
import Image from "next/image";
import { FormattedMessage } from "react-intl";
import styles from "../style.module.scss";
import imageFeedback1 from "../../../../public/assets/images/feedback1.webp";
import imageFeedback2 from "../../../../public/assets/images/feedback2.webp";
import imageFeedback3 from "../../../../public/assets/images/feedback3.webp";
import imageFeedback4 from "../../../../public/assets/images/feedback4.webp";
import imageFeedback5 from "../../../../public/assets/images/feedback5.webp";
import imageFeedback6 from "../../../../public/assets/images/feedback6.webp";
import imageFeedback7 from "../../../../public/assets/images/feedback7.webp";
import imageFeedback8 from "../../../../public/assets/images/feedback8.webp";
import IconStar from "../../../../public/assets/icons/IconStar";
import { TTheme } from "../../../utils/configs/setupTheme";
import CustomSlider from "../../common/components/CustomSlider";

function Feedback() {
  const matches1300 = useMediaQuery("(min-width:1300px)");
  const isMobile = useMediaQuery("(max-width:520px)");
  const isTabletPro = useMediaQuery("(max-width:1024px)");
  const theme: TTheme = useTheme();
  return (
    <Box
      className="flex flex-col bg-white py-[24px] px-4 lg:rounded-[6px] md:rounded-[6px] sm:rounded-0 text-black"
      style={{ backgroundColor: "white" }}
    >
      <span
        className="text-black font-semibold text-lg mb-2"
        style={{ fontWeight: "bold", display: "flex", width: "100%" }}
        id="home_page_feedback_title"
      >
        Feedback
      </span>
      <div>
        <CustomSlider
          isDots={false}
          autoplay
          // eslint-disable-next-line no-nested-ternary
          slidesToShow={isMobile ? 2 : isTabletPro ? 3 : 4}
          slidesToScroll={1}
          centerMode={false}
        >
          <Box
            className={`${styles["box-feedback"]} shadow-md lg:my-2 md:ml-2 md:my-1 rounded-[16px] h-[168px] p-[10px] sm:h-[168px] sm:my-1 sm:ml-[4px]`}
            id="home_page_feedback_item"
          >
            <Box display="flex" alignItems="center" className="gap-2 h-[56px] mb-2">
              <Image
                src={imageFeedback1}
                alt=""
                className={`${styles["images-feedback"]} rounded-[50%]`}
                width={40}
                height={40}
              />
              <Typography variant="subtitle1" className="text-blackQuiz text-[15px] font-medium">
                Hoan Hoan
              </Typography>
            </Box>
            <div className="flex flex-col">
              <Rating size={!matches1300 ? "small" : "medium"} readOnly value={5} icon={<IconStar />} />
              <Typography variant="caption" style={{ marginTop: 10, color: theme.palette.primary.black1 }}>
                <FormattedMessage id="great" />
              </Typography>
            </div>
          </Box>
          <Box
            className={`${styles["box-feedback"]} shadow-md lg:my-2 md:mx-2 md:my-1 lg:mx-2 rounded-[16px] h-[168px] p-[10px] sm:my-1 sm:mx-1`}
            id="home_page_feedback_item"
          >
            <Box display="flex" alignItems="center" className="gap-2 h-[56px] mb-2">
              <Image
                src={imageFeedback2}
                alt=""
                className={`${styles["images-feedback"]} rounded-[50%]`}
                width={40}
                height={40}
              />
              <Typography variant="subtitle1" className="text-blackQuiz text-[15px] font-medium">
              Hoang Nguyen
              </Typography>
            </Box>
            <div className="flex flex-col">
              <Rating size={!matches1300 ? "small" : "medium"} readOnly value={5} icon={<IconStar />} />
              <Typography variant="caption" style={{ marginTop: 10, color: theme.palette.primary.black1 }}>
                <FormattedMessage id="very.good" />
              </Typography>
            </div>
          </Box>
          <Box
            className={`${styles["box-feedback"]} shadow-md lg:my-2 md:mx-2 md:my-1 lg:mx-2 rounded-[16px] h-[168px] p-[10px] sm:h-[168px] sm:my-1 sm:mx-1`}
            id="home_page_feedback_item"
          >
            <Box display="flex" alignItems="center" className="gap-2 h-[56px] mb-2">
              <Image
                className={`${styles["images-feedback"]} rounded-[50%]`}
                width={40}
                height={40}
                src={imageFeedback3}
                alt=""
              />
              <Typography variant="subtitle1" className="text-blackQuiz text-[15px] font-medium">
              Name No
              </Typography>
            </Box>
            <div className="flex flex-col">
              <Rating size={!matches1300 ? "small" : "medium"} readOnly value={5} icon={<IconStar />} />
              <Typography variant="caption" style={{ marginTop: 10, color: theme.palette.primary.black1 }}>
                <FormattedMessage id="feedback.3" />
              </Typography>
            </div>
          </Box>
          <Box
            className={`${styles["box-feedback"]} shadow-md lg:my-2 md:mx-2 md:my-1 lg:mx-2 rounded-[16px] h-[168px] p-[10px] sm:h-[168px] sm:my-1 sm:mx-1`}
            id="home_page_feedback_item"
          >
            <Box display="flex" alignItems="center" mb={3 / 2} className="gap-2 h-[56px] mb-2">
              <Image
                className={`${styles["images-feedback"]} rounded-[50%]`}
                width={40}
                height={40}
                src={imageFeedback4}
                alt=""
              />
              <Typography variant="subtitle1" className="text-blackQuiz text-[15px] font-medium">
              haitru luong
              </Typography>
            </Box>
            <div className="flex flex-col">
              <Rating size={!matches1300 ? "small" : "medium"} readOnly value={5} icon={<IconStar />} />
              <Typography variant="caption" style={{ marginTop: 10, color: theme.palette.primary.black1 }}>
                {/* Xịn lắm mn ạ 💙 */}
                <FormattedMessage id="feedback.4" />
              </Typography>
            </div>
          </Box>
          <Box
            className={`${styles["box-feedback"]} shadow-md lg:my-2 md:mx-2 md:my-1 rounded-[16px] h-[168px] p-[10px] sm:h-[168px] sm:my-1 sm:mx-1`}
            id="home_page_feedback_item"
          >
            <Box display="flex" alignItems="center" className="gap-2 h-[56px] mb-2">
              <Image
                src={imageFeedback5}
                alt=""
                className={`${styles["images-feedback"]} rounded-[50%]`}
                width={40}
                height={40}
              />
              <Typography variant="subtitle1" className="text-blackQuiz text-[15px] font-medium">
              Ngân Ngân
              </Typography>
            </Box>
            <div className="flex flex-col">
              <Rating size={!matches1300 ? "small" : "medium"} readOnly value={5} icon={<IconStar />} />
              <Typography variant="caption" style={{ marginTop: 10, color: theme.palette.primary.black1 }}>
                <FormattedMessage id="feedback.5" />
              </Typography>
            </div>
          </Box>
          <Box
            className={`${styles["box-feedback"]} shadow-md lg:my-2 md:mx-2 md:my-1 lg:mx-2 rounded-[16px] h-[168px] p-[10px] sm:my-1 sm:mx-1`}
            id="home_page_feedback_item"
          >
            <Box display="flex" alignItems="center" className="gap-2 h-[56px] mb-2">
              <Image
                src={imageFeedback6}
                alt=""
                className={`${styles["images-feedback"]} rounded-[50%]`}
                width={40}
                height={40}
              />
              <Typography variant="subtitle1" className="text-blackQuiz text-[15px] font-medium">
              vân anh
              </Typography>
            </Box>
            <div className="flex flex-col">
              <Rating size={!matches1300 ? "small" : "medium"} readOnly value={5} icon={<IconStar />} />
              <Typography variant="caption" style={{ marginTop: 10, color: theme.palette.primary.black1 }}>
                <FormattedMessage id="feedback.6" />
              </Typography>
            </div>
          </Box>
          <Box
            className={`${styles["box-feedback"]} shadow-md lg:my-2 md:mx-2 md:my-1 lg:mx-2 rounded-[16px] h-[168px] p-[10px] sm:h-[168px] sm:my-1 sm:mx-1`}
            id="home_page_feedback_item"
          >
            <Box display="flex" alignItems="center" className="gap-2 h-[56px] mb-2">
              <Image
                className={`${styles["images-feedback"]} rounded-[50%]`}
                width={40}
                height={40}
                src={imageFeedback7}
                alt=""
              />
              <Typography variant="subtitle1" className="text-blackQuiz text-[15px] font-medium">
              Nguyệt Minh
              </Typography>
            </Box>
            <div className="flex flex-col">
              <Rating size={!matches1300 ? "small" : "medium"} readOnly value={5} icon={<IconStar />} />
              <Typography variant="caption" style={{ marginTop: 10, color: theme.palette.primary.black1 }}>
                <FormattedMessage id="feedback.7" />
              </Typography>
            </div>
          </Box>
          <Box
            className={`${styles["box-feedback"]} shadow-md lg:my-2 md:mx-2 md:my-1 lg:mx-2 rounded-[16px] h-[168px] p-[10px] sm:h-[168px] sm:my-1 sm:mx-1`}
            id="home_page_feedback_item"
          >
            <Box display="flex" alignItems="center" mb={3 / 2} className="gap-2 h-[56px] mb-2">
              <Image
                className={`${styles["images-feedback"]} rounded-[50%]`}
                width={40}
                height={40}
                src={imageFeedback8}
                alt=""
              />
              <Typography variant="subtitle1" className="text-blackQuiz text-[15px] font-medium">
              Lê My
              </Typography>
            </Box>
            <div className="flex flex-col">
              <Rating size={!matches1300 ? "small" : "medium"} readOnly value={5} icon={<IconStar />} />
              <Typography variant="caption" style={{ marginTop: 10, color: theme.palette.primary.black1 }}>
                {/* Xịn lắm mn ạ 💙 */}
                <FormattedMessage id="feedback.8" />
              </Typography>
            </div>
          </Box>
        </CustomSlider>
      </div>
    </Box>
  );
}

export default Feedback;
