import { isEqual } from "lodash";
import Image from "next/image";
import Link from "next/link";
import React, { useEffect } from "react";
import { useStore } from "react-redux";
import { eventTracking } from "../../../firebase/firebaseConfig";
import CustomSlider from "../../common/components/CustomSlider";
import GoogleAd from "../../common/components/GoogleAd";
import { TDataAdsInHouse } from "../../common/utils/type";

const SPECIAL_LINK_PARAM_MISSION = "nhiem-vu";
const MISSION_ROUTER = "kim-cuong";

type TProps = {
  title: string;
  adsInHouse: TDataAdsInHouse[];
  matches1100: boolean;
  width: number;
  handleClickAds: any;
};
const BannerAds: React.FC<TProps> = ({ title, adsInHouse, matches1100, width, handleClickAds }) => {
  const store = useStore();
  const getCustomLink = (link) => {
    // do router mission app va web khac nhau, nen custom lai
    const linkSplit = link.split("/");
    const lastParamsLink = linkSplit[linkSplit.length - 1];
    if (lastParamsLink === SPECIAL_LINK_PARAM_MISSION) {
      return link.replace(SPECIAL_LINK_PARAM_MISSION, MISSION_ROUTER);
    }
    return link;
  };

  useEffect(() => {
    eventTracking("ads_banner_native_ads_view", { device: navigator?.userAgent });
  }, []);

  return (
    <div className={`${title && "bg-white rounded-md p-4"}`} id="home_page_banner_ads">
      <h3 className="text-black font-semibold text-lg mb-2 line-clamp-2" id="home_page_banner_ads_title">
        {title}
      </h3>
      <div>
        {adsInHouse?.length > 0 ? (
          <CustomSlider slidesToShow={1} isDots autoplay centerMode={false}>
            {adsInHouse?.map((item) => (
              <Link
                key={item?.activity_uid}
                href={getCustomLink(item?.link)}
                className="sm:sm:mt-[12px] md:mt-6 w-full"
                id="home_page_ads_in_house_item"
              >
                <a
                  href={getCustomLink(item?.link)}
                  target="_blank"
                  rel="noreferrer"
                  onClick={() =>
                    eventTracking("select_home_ads_item", { link: item?.link, item_id: item?.activity_uid }, () => {
                      handleClickAds(store, item?.activity_uid);
                    })
                  }
                >
                  <Image
                    src={item?.media}
                    alt={item?.content}
                    height={!matches1100 ? 166 : 192}
                    width={width}
                    style={{
                      display: "block",
                      objectFit: "cover"
                    }}
                    layout={width ? "responsive" : "fill"}
                    className="rounded-md my-auto"
                  />
                </a>
              </Link>
            ))}
          </CustomSlider>
        ) : (
          <div className="">
            <GoogleAd
              style={{
                display: "block",
                height: !matches1100 ? 166 : 192,
                width
              }}
              slot={process.env.NEXT_PUBLIC_GOOGLE_AD_SLOT_BANNER}
            />
          </div>
        )}
      </div>
    </div>
  );
};
const checkEqualProps = (prevProps, nextProps) => {
  return (
    isEqual(prevProps?.adsInHouse, nextProps?.adsInHouse) &&
    isEqual(prevProps.width, nextProps.width) &&
    isEqual(prevProps.matches1100, nextProps.matches1100)
  );
};
export default React.memo(BannerAds, checkEqualProps);
