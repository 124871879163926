import React from "react";
import { LIST_EXTRA_INFO } from "../utils/constants";

const SuggestSubjectsCard = ({ item }) => {
  return (
    <div className="flex flex-col">
      <div className="line-clamp-2 text-start h-[50px]">
        <span className="text-green text-[15px]">{item?.subject_type?.title}: </span>
        <span>{item?.title}</span>
      </div>
      <div className="flex flex-row items-center w-full gap-1 text-gray400 text-[12px] flex-wrap h-[40px]">
        <span className={`my-2 ${item?.extra_info?.type === "practice" && "mr-[-3px]"}`}>{LIST_EXTRA_INFO.find((ele) => ele?.type === item?.extra_info?.type)?.title}</span>
        <span>{item?.extra_info?.quiz_time > 0 && ` - ${item?.extra_info?.quiz_time} phút  `}</span>
        {item?.extra_info?.num_of_questions && <span> - {item?.extra_info?.num_of_questions} câu </span>}
        {item?.extra_info?.views > 0 && (
          <span>
            - {item?.extra_info?.views} người đã
            {item?.extra_info?.type === "lesson" ? " xem" : " làm"}
          </span>
        )}
      </div>
    </div>
  );
};

export default React.memo(SuggestSubjectsCard);
