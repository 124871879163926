import { Typography } from "@mui/material";
import Link from "@mui/material/Link";
import { Box } from "@mui/system";
import { useRouter } from "next/router";
import React, { useEffect } from "react";
import { isEqual } from "lodash";
import { useStore } from "react-redux";
import { eventTracking } from "../../../firebase/firebaseConfig";
import { hexToRgbA } from "../../../utils/helpers/helpers";
import CustomSlider from "../../common/components/CustomSlider";
import { TDataAdsInHouse } from "../../common/utils/type";
import NextImage from "../../common/Image/NextImage";

export const customStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  zIndex: 2,
  borderRadius: "100px",
  cursor: "pointer",
  position: "absolute",
  top: "50%",
  transform: "translate(-50%, -50%)"
};
type TProps = {
  banners: {
    ordinal: number;
    photo_url: string;
    web_link: string;
    app_link: string;
    content: string;
    text_color?: string;
    background_color?: string;
    shape?: string;
  }[];
  adsInHouse: TDataAdsInHouse[];
  handleClickAds: any;
};

function Banner({ banners, adsInHouse, handleClickAds }: TProps) {
  const router = useRouter();
  const store = useStore();

  useEffect(() => {
    eventTracking("ads_banner_native_ads_view", { device: navigator?.userAgent });
  }, []);

  return (
    <CustomSlider slidesToShow={1} isDots autoplay centerMode={false}>
      {adsInHouse?.map((item) => (
        <Link key={item?.activity_uid} href={item?.link} className="" id="banner_ads_in_house_item">
          <a
            href={item?.link}
            target="_blank"
            rel="noreferrer"
            onClick={() => handleClickAds(store, item?.activity_uid)}
          >
            <NextImage
              src={item?.media}
              className="w-full h-full bg-white lg:rounded-[6px] md:rounded-[6px] sm:rounded-0 object-contain aspect-[3/1]"
              alt="ads banner"
            />
          </a>
        </Link>
      ))}
      {banners?.length > 0
        ? banners?.map((ele, index) => {
            return ele?.photo_url ? (
              <Box key={`${index}`}>
                <Link
                  href={ele?.web_link}
                  target="_blank"
                  onClick={() =>
                    eventTracking("select_promotion_item", { title: ele?.content, link: ele?.web_link }, () => {})
                  }
                  id="home-page-promotion-item"
                >
                  <NextImage
                    src={ele?.photo_url}
                    className="w-full h-full bg-white lg:rounded-[6px] md:rounded-[6px] sm:rounded-0 object-contain aspect-[3/1]"
                    alt="banner"
                  />
                </Link>
              </Box>
            ) : (
              <Box key={`${index}`}>
                <Box
                  key={index}
                  style={{
                    backgroundColor: hexToRgbA(ele?.background_color as string),
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: 6,
                    color: hexToRgbA(ele?.text_color as string)
                  }}
                  className="aspect-[3/1]"
                  onClick={() =>
                    eventTracking("select_promotion_item", { title: ele?.content, link: ele?.web_link }, () => {
                      router.push(ele?.web_link);
                    })
                  }
                  id="home-page-promotion-item"
                >
                  <Typography variant="h5">{ele?.content}</Typography>
                </Box>
              </Box>
            );
          })
        : adsInHouse?.length === 0 && (
            <div className="w-full bg-white flex justify-center rounded-md">
              <NextImage
                src="/assets/images/defaultBanner.webp"
                className="aspect-[3/1] w-full object-contain"
                alt="default banner"
              />
            </div>
          )}
    </CustomSlider>
  );
}
const checkEqualProps = (prevProps, nextProps) => {
  return isEqual(prevProps?.banners, nextProps?.banners) && isEqual(prevProps?.adsInHouse, nextProps?.adsInHouse);
};
export default React.memo(Banner, checkEqualProps);
