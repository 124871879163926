import { Box } from "@mui/material";
import React, { useMemo } from "react";
import { FormattedMessage } from "react-intl";
import Image from "next/image";
import { useRouter } from "next/router";
import { useDispatch, useSelector } from "react-redux";
import EarnDiamond from "../../../../public/assets/images/EarnDiamond.webp";
import Gift from "../../../../public/assets/images/Gift.webp";
import Game from "../../../../public/assets/images/Game.webp";
import TargetDialog from "../../account/components/TargetDialog";
import { commonActions } from "../../common/redux/actions";

import { eventTracking } from "../../../firebase/firebaseConfig";
import { TCommonState } from "../../common/utils/type";
import { TConfig } from "../utils/types";
import { CONFIG } from "../../common/utils/constant";

interface UsefulFeaturesProps {
  title: string;
}

function UsefulFeatures({ title }: UsefulFeaturesProps) {
  const router = useRouter();
  const [openTargetDialog, setOpenTargetDialog] = React.useState<boolean>(false);
  const dispatch = useDispatch();
  const { userInfo, topInfos } = useSelector((state: { commonNew: TCommonState }) => ({
    userInfo: state.commonNew.userInfos,
    topInfos: state.commonNew.listTops
  }));

  const gameName = useMemo(
    () => topInfos?.config_list?.find((config: TConfig) => config.key === CONFIG.GAME_NAME),
    [topInfos?.config_list]
  );
  const gameLink = useMemo(
    () => topInfos?.config_list?.find((config: TConfig) => config.key === CONFIG.GAME_LINK),
    [topInfos?.config_list]
  );

  return (
    <>
      <Box className="md:w-full sm:w-screen flex flex-col bg-white lg:rounded-[6px] md:rounded-[6px] sm:rounded-0 px-4 py-6">
        <span className="text-black font-semibold text-lg mb-2" id="home_page_useful_feature_title">
          {title}
        </span>
        <div className="flex flex-1 overflow-auto scrollbar-hide pl-2">
          <div className="flex sm:gap-x-5 md:gap-x-[27px] min-w-min py-1 mt-2">
            {gameName && gameLink && (
              <button
                type="button"
                className="w-[96px] h-max line-clamp-2 py-4 rounded-[16px] min-h-[130px]"
                onClick={() => {
                  if (typeof userInfo?.member?.pub_id === "undefined") {
                    dispatch(commonActions.handleOpenModalLogin({ isOpen: true }));
                  } else {
                    router.push(gameLink.value);
                  }
                }}
                style={{
                  boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.15)"
                }}
                id="home_page_useful_feature_item"
              >
                <div className="flex justify-center mb-[22px]">
                  <Image src={Game} width={62} height={36} />
                </div>
                <span
                  className="text-blackQuiz text-[15px] mt-[10px] px-2 h-[40px] overflow-hidden"
                  style={{ display: "-webkit-box", WebkitLineClamp: 2, WebkitBoxOrient: "vertical" }}
                >
                  {gameName.value}
                </span>
              </button>
            )}
            <button
              type="button"
              className="w-[96px] h-max line-clamp-2 py-4 rounded-[16px] min-h-[130px]"
              onClick={() => {
                if (typeof userInfo?.member?.pub_id === "undefined") {
                  dispatch(commonActions.handleOpenModalLogin({ isOpen: true }));
                } else router.push("/qua-tang");
              }}
              style={{
                boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.15)"
              }}
              id="home_page_useful_feature_item"
            >
              <div className="flex justify-center">
                <Image src={Gift} width={46} height={48} />
              </div>
              <span
                className="flex text-blackQuiz text-[15px] justify-center items-center mt-[10px] px-2 h-[40px]"
                id="home_page_useful_feature_item_title"
              >
                <FormattedMessage id="Profile.gift" />
              </span>
            </button>
            <button
              type="button"
              className="w-[96px] h-max line-clamp-2 py-4 rounded-[16px]"
              onClick={() =>
                eventTracking("select_other_item", {}, () => {
                  if (typeof userInfo?.member?.pub_id === "undefined") {
                    dispatch(commonActions.handleOpenModalLogin({ isOpen: true }));
                  } else {
                    setOpenTargetDialog(true);
                  }
                })
              }
              id="home_page_useful_feature_item"
              style={{
                boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.15)"
              }}
            >
              <div className="flex justify-center ">
                <Image src="/assets/images/TargetLearningIcon.webp" width={48} height={48} />
              </div>
              <span
                className="flex text-blackQuiz text-[15px] justify-center mt-[10px] px-2 h-[40px]"
                id="home_page_useful_feature_item_title"
              >
                <FormattedMessage id="UsefulFeatures.target" />
              </span>
            </button>
            <button
              type="button"
              className="w-[96px] h-max line-clamp-2 py-4 rounded-[16px]"
              onClick={() => {
                eventTracking("select_other_item", {}, () => {
                  if (typeof userInfo?.member?.pub_id === "undefined") {
                    dispatch(commonActions.handleOpenModalLogin({ isOpen: true }));
                  } else router.push("/kim-cuong");
                });
              }}
              style={{
                boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.15)"
              }}
              id="home_page_useful_feature_item"
            >
              <div className="flex justify-center ">
                <Image src={EarnDiamond} width={34} height={48} />
              </div>
              <span
                className="flex text-blackQuiz text-[15px] justify-center mt-[10px] px-2 h-[40px]"
                id="home_page_useful_feature_item_title"
              >
                <FormattedMessage id="Profile.earnMoney" />
              </span>
            </button>
          </div>
        </div>
      </Box>
      {openTargetDialog && <TargetDialog open={openTargetDialog} setOpen={setOpenTargetDialog} />}
    </>
  );
}
export default React.memo(UsefulFeatures);
