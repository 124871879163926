/* eslint-disable no-nested-ternary */
import React from "react";

import { useMediaQuery } from "@mui/material";
import { isEqual } from "lodash";
import SuggestSubjectsCard from "./SuggestSubjectsCard";
import CustomSlider from "../../common/components/CustomSlider";
import { eventTracking } from "../../../firebase/firebaseConfig";

const SuggestSubjectComponent = (props) => {
  const { suggestSubjects, handleDirectPage } = props;
  const desktop = useMediaQuery("(min-width:1440px)");  
  return (
    <div className="bg-white lg:rounded-[6px] md:rounded-[6px] sm:rounded-0 pt-4" id="home_page_suggest_subjects">
      <span className="text-black font-semibold text-lg line-clamp-2 px-4" id="home_page_suggest_subjects_title">
        {suggestSubjects?.title || ""}
      </span>
      <div className="w-full px-4">
        {suggestSubjects?.items?.length > 2 ? (
          <CustomSlider
            isDots={false}
            autoplay
            centerMode={false}
            slidesToShow={!desktop ? 1 : 2}
            className="px-2 gap-x-4 pb-3 pt-2"
          >
            {suggestSubjects?.items?.map((item) => (
              <button
                key={item?.id}
                type="button"
                className="sm:w-[93%] min-h-[107px] max-h-max rounded-[6px] px-4 py-4 mr-4 my-2 cursor-pointer box-shadow-feedback"
                id="home_page_suggest_subjects_item"
                onClick={() =>
                  eventTracking(
                    "select_suggest_item",
                    { slug: item?.slug, title: item?.title, id: item?.id, link: item?.web_link },
                    () => handleDirectPage(item?.extra_info, item?.direct_link)
                  )
                }
              >
                <SuggestSubjectsCard item={item} />
              </button>
            ))}
          </CustomSlider>
        ) : (
          suggestSubjects?.items?.map((item) => (
            <button
              key={item?.id}
              type="button"
              className="sm2:w-[322px] sm:w-[93%] min-h-[107px] max-h-max rounded-[6px] drop-shadow-md shadow-md px-4 py-4 mr-4 my-2 cursor-pointer"
              id="home_page_suggest_subjects_item"
              onClick={() =>
                eventTracking(
                  "select_suggest_item",
                  { slug: item?.slug, title: item?.title, id: item?.id, link: item?.web_link },
                  () => handleDirectPage(item?.extra_info, item?.direct_link)
                )
              }
            >
              <SuggestSubjectsCard item={item} />
            </button>
          ))
        )}
      </div>
    </div>
  );
};
const checkEqualProps = (prevProps, nextProps) => {
  return isEqual(prevProps?.suggestSubjects, nextProps?.suggestSubjects);
};
export default React.memo(SuggestSubjectComponent, checkEqualProps);
