import { ACTION_TYPES_HOME } from "./actionTypes";

const handleGetGiftCode = () => {
  return {
    type: ACTION_TYPES_HOME.GET_GIFT_CODE
  };
};
const handleTakeGiftCode = (params) => {
  return {
    type: ACTION_TYPES_HOME.HANDLE_TAKE_GIFT_CODE,
    params
  };
};
const handleClearNotifyData = () => {
  return {
    type: ACTION_TYPES_HOME.CLEAR_NOTIFY
  };
};

export const homeActions = {
  handleGetGiftCode,
  handleTakeGiftCode,
  handleClearNotifyData,
};
