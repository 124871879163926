import { useMediaQuery } from "@mui/material";
import Link from "next/link";
import React from "react";
import { isEqual } from "lodash";
import { useStore } from "react-redux";
import Image from "next/image";
import CustomSlider from "../../common/components/CustomSlider";
import { TRecommendList } from "../utils/types";
import RecommendCard from "./RecommendCard";
import { TDataAdsInHouse } from "../../common/utils/type";
// import NextImage from "../../common/Image/NextImage";

type TProps = {
  recommend: TRecommendList;
  adsInHouse: TDataAdsInHouse[];
  handleClickAds: any;
};
const RecommendComponent: React.FC<TProps> = ({ recommend, adsInHouse, handleClickAds }) => {
  const recommendRef: any = React.useRef();
  const store = useStore();
  const [rectAds, setRectAds] = React.useState<any>({ width: 0, height: 0 });

  React.useEffect(() => {
    if (recommendRef?.current) {
      // eslint-disable-next-line no-unsafe-optional-chaining
      setRectAds({ width: recommendRef?.current?.clientWidth, height: recommendRef?.current?.clientHeight });
    }
  }, [recommendRef]);
  const mobile = useMediaQuery("(min-width:520px)");
  return (
    <div className=" bg-white lg:rounded-[6px] md:rounded-[6px] sm:rounded-0 py-4">
      <span className="text-black font-semibold text-lg px-4 line-clamp-2" id="home_page_recommended_title">
        {recommend?.title || ""}
      </span>
      <div className="px-4">
        {(recommend?.items?.length as number) + (adsInHouse?.length as number) > 2 || !mobile ? (
          <CustomSlider
            isDots={false}
            autoplay={false}
            centerMode={false}
            slidesToShow={!mobile ? 1 : 2}
            className="gap-x-4 py-4 flex h-full"
          >
            {adsInHouse?.map((item) => (
              <Link
                href={item?.link}
                key={item?.activity_uid}
                onClick={() => handleClickAds(store, item?.activity_uid)}
                className="rounded-[6px] shadow-md my-2 ml-[12px] mr-[10px] mx-2"
              >
                {/* <NextImage
                  src={item?.media}
                  alt={item?.content}
                  className={`mr-[20px] w-[${rectAds.width}px] h-[${rectAds.height}px] mt-[10px]`}
                  objectFit=""
                  classImage="rounded-[6px]"
                /> */}
                <Image
                  src={item?.media}
                  width={rectAds.width}
                  height={rectAds.height}
                  sizes="100vw"
                  className="rounded-[6px] mr-[20px] mt-[10px]"
                />
              </Link>
            ))}
            {recommend?.items?.map((item) => (
              <div
                key={item?.id}
                className="rounded-[6px] shadow-md my-2 w-[95%]"
                ref={recommendRef}
                id="home_page_recommended_item"
              >
                <RecommendCard recommendItem={item} />
              </div>
            ))}
          </CustomSlider>
        ) : (
          <div className="flex flex-row items-center overflow-hidden">
            {recommend?.items?.length > 0 &&
              recommend?.items.map((item) => (
                <div
                  key={item?.id}
                  className="xl:w-1/3 rounded-[6px] drop-shadow-md shadow-md first:mr-4 my-2 sm:w-full  md:w-1/2 min-w-[350px]"
                >
                  <RecommendCard recommendItem={item} />
                </div>
              ))}
            {adsInHouse?.length > 0 &&
              adsInHouse?.map((item) => (
                <Link
                  href={item?.link}
                  key={item?.activity_uid}
                  onClick={() => handleClickAds(store, item?.activity_uid)}
                  className="rounded-[6px] shadow-md my-2 w-[95%]"
                  id="home_page_recommended_item"
                >
                  <Image
                    src={item?.media}
                    width={rectAds.width}
                    height={rectAds.height}
                    sizes="100vw"
                    className="rounded-[6px] mr-[20px] mt-[10px]"
                  />
                </Link>
              ))}
          </div>
        )}
      </div>
    </div>
  );
};
const checkEqualProps = (prevProps, nextProps) => {
  return isEqual(prevProps?.recommend, nextProps?.recommend) && isEqual(prevProps?.adsInHouse, nextProps?.adsInHouse);
};
export default React.memo(RecommendComponent, checkEqualProps);
