/* eslint-disable no-unused-vars */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable no-console */
/* eslint-disable func-names */
import type { NextPage } from "next";
import React from "react";
import { END } from "redux-saga";
import Home from "../components/features/Homepage/pages/Home";
import Layout from "../components/features/Default/layout/layout";
import { wrapper } from "../app/store";
import { commonActions } from "../components/features/common/redux/actions";
import { getTopInfosSSR, getNewsHomePageSSR, getTopExtraInfosSSR } from "./api/home";

const HomePage: NextPage = (props) => {
  return (
    <Layout pageProps={props}>
      <Home props={props} />
    </Layout>
  );
};

export const getServerSideProps = wrapper.getServerSideProps((store) => async ({ resolvedUrl }) => {
  const handleGetHomePageData = async () => {
    const grade = store.getState()?.commonNew?.grade;
    // if (isInitialPageRequest(context.req)) {
    //   await getTopExtraInfosSSR(store, { grade })
    //     .then((res: any) => store.dispatch(commonActions.handleGetTopExtraSuccess({ ...res?.data?.data, grade })))
    //     .catch((err) => err);
    // }
    if (grade === store?.getState()?.commonNew?.listTops?.grade) return;
    await Promise.all([
      getTopInfosSSR(store, { grade })
        .then((res: any) => store.dispatch(commonActions.handleGetTopSuccess({ ...res?.data?.data, grade })))
        .catch((err) => err),
      getNewsHomePageSSR(store)
        .then((res: any) => store.dispatch(commonActions.handleGetNewsSuccess(res?.data?.data)))
        .catch((err) => err)
    ]);
  };
  await handleGetHomePageData();
  // if (isInitialPageRequest(context.req)) {
  //   await handleGetHomePageData();
  // }

  // end the saga
  store.dispatch(END);
  await store?.sagaTask.toPromise();
  return {
    props: {
      topInfos: store?.getState()?.commonNew?.listTops,
      listNews: store?.getState()?.commonNew?.listNews
    }
  };
});
export default HomePage;
